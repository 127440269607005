<template>
  <router-view></router-view>
</template>

<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import { saveAs } from "file-saver";
import resourceDialog from "../../components/resource_dialog.vue";
import axios from "axios";
import CountryFlag from "vue-country-flag";
import ResourceCard from "../../components/resource_card.vue";
export default {
  name: "Resources",
  components: {
    menuTitle,
    resourceDialog,
    CountryFlag,
    ResourceCard,
  },
  computed: {
    filteredFiles: function () {
      var res = this.files;
      if (this.search) {
        res = res.filter((x) =>
          x.displayName.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.applicationFilter) {
        res = res.filter((x) => x.application == this.applicationFilter);
      }
      if (this.languageFilter) {
        res = res.filter((x) => x.language == this.languageFilter);
      }
      if (this.typeFilter) {
        res = res.filter((x) => x.type == this.typeFilter);
      }
      return res;
    },
  },
  methods: {
    debug: function (fileName) {
      console.log("debug...");
      console.log(fileName);
      const pdfFile = require(`../../assets/marketing_material/${fileName}.pdf`);
      console.log(pdfFile);
      axios
        .get("http://localhost:8081" + pdfFile)
        .then((response) => {
          //console.log("Response:");
          //console.log(response);
          var length = Number(response.headers["content-length"]);
          console.log(length);
          console.log(length / 1000 / 1000);
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
      console.log("----------");
    },
    getImage: function (fileName) {
      return require(`../../assets/marketing_material/${fileName}.png`);
    },
    getPdf: function (fileName) {
      return require(`../../assets/marketing_material/${fileName}.pdf`);
    },
    downloadFile: function (file) {
      const pdf = this.getPdf(file.fileName);
      saveAs(pdf, file.displayName + ".pdf");
    },
  },
  data: () => ({
    fabs: {},
    selectedResource: {},
    previewDialog: false,
    search: "",

    applicationFilter: "",
    applicationFilterOptions: [
      { name: "All Solutions", value: "" },
      { name: "COBI.edi", value: "edi" },
      { name: "COBI.msv", value: "msv" },
      { name: "COBI.ppc", value: "ppc" },
      { name: "COBI.time", value: "time" },
      { name: "COBI.wms", value: "wms" },
    ],

    languageFilter: "",
    languageFilterOptions: [
      { name: "All Languages", value: "" },
      { name: "English", value: "gb" },
      { name: "German", value: "de" },
    ],

    typeFilter: "",
    typeFilterOptions: [
      { name: "All types", value: "" },
      { name: "Partner Price List", value: "partner_pricelist" },
      { name: "Factsheets", value: "factsheet" },
      { name: "Information", value: "information" },
      { name: "Solution Overview", value: "solution_overview" },
      { name: "Manuals", value: "manual" },
      { name: "Roadmap", value: "roadmap" },
    ],
    files: [
      // - NEW PRICELISTS -
      {
        application: "edi",
        displayName: "COBI.edi Partnerpreisliste 2023",
        fileName: "edi/pricelists/partner/de_edi_partner_pricelist",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "edi",
        displayName: "COBI.edi Partner Pricelist 2023",
        fileName: "edi/pricelists/partner/eng_edi_partner_pricelist",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },

      {
        application: "ppc",
        displayName: "COBI.ppc Partnerpreisliste 2023",
        fileName: "ppc/pricelists/partner/de_ppc_partner_pricelist_2023",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "ppc",
        displayName: "COBI.ppc Partner Price List 2023",
        fileName: "ppc/pricelists/partner/eng_ppc_partner_pricelist_2023",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },

      {
        application: "wms",
        displayName: "COBI.wms Partnerpreisliste 2023",
        fileName: "wms/pricelists/partner/de_wms_partner_pricelist_2023",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "wms",
        displayName: "COBI.wms Partner Price List 2023",
        fileName: "wms/pricelists/partner/eng_wms_partner_pricelist_2023",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },

      {
        application: "ppc",
        displayName: "COBI.ppc Partnerpreisliste 2023",
        fileName: "ppc/pricelists/partner/de_ppc_partner_pricelist_2023",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "ppc",
        displayName: "COBI.ppc Partnerpreisliste 2023",
        fileName: "ppc/pricelists/partner/de_ppc_partner_pricelist_2023",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "ppc",
        displayName: "COBI.ppc Partnerpreisliste 2020",
        fileName: "ppc/pricelists/partner/de_ppc_partner_pricelist",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "ppc",
        displayName: "COBI.ppc Partner Price List 2020",
        fileName: "ppc/pricelists/partner/eng_ppc_partner_pricelist",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "time",
        displayName: "COBI.time Partnerpreisliste 2022",
        fileName: "time/pricelists/partner/de_time_partner_pricelist",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "time",
        displayName: "COBI.time Partnerpreisliste 2022",
        fileName: "time/pricelists/partner/eng_time_partner_pricelist",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "wms",
        displayName: "COBI.wms Partnerpreisliste 2020",
        fileName: "wms/pricelists/partner/de_wms_partner_pricelist",
        language: "de",
        type: "partner_pricelist",
        format: "pdf",
      },
      {
        application: "wms",
        displayName: "COBI.wms Partnerpreisliste 2020",
        fileName: "wms/pricelists/partner/eng_wms_partner_pricelist",
        language: "gb",
        type: "partner_pricelist",
        format: "pdf",
      },
    ],
  }),
};
</script>
<style scoped>
.show_outline {
  outline: black;
}
.no_results_span_1 {
  font-size: 1.5rem !important;
}
.no_results_span_2 {
  font-size: 1.2rem !important;
}
</style>
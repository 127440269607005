<template>
  <div
    class="d-flex container"
    :style="'height: ' + height + 'px;'"
  >
    <!-- Image -->
    <div
      class="d-flex align-content-start justify-content-start"
      style="width: 45%; height: inherit;"
    >
      <v-img
        :height="(height + 10) + 'px'"
        width="inherit"
        :src="getImgSrc()"
        style="transform: translate(-2px, -4px);"
        position="center top"
      ></v-img>
    </div>

    <div
      style="width: 55%"
      class="d-flex flex-column"
    >
      <!-- Flag -->
      <div
        class="d-flex justify-end"
        style="width: 100%; height: 20%"
      >
        <country-flag
          class="mr-1"
          :country="language"
          size="normal"
        ></country-flag>
      </div>

      <!-- Title -->
      <div
        class="d-flex justify-start align-start pl-5 pr-5"
        style="width: 100%; height: 50%"
      >
        <span style="font-weight: 550; font-size: 1.2rem;">{{ name }}</span>
      </div>

      <!-- Buttons -->
      <div
        class="d-flex align-end justify-end"
        style="width: 100%; height: 30%"
      >
        <!--
        <v-speed-dial
          v-model="fab"
          bottom
          right
          direction="left"
          open-on-hover
          transition="slide-x-transition"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              fab
              color="#b31616"
              outlined
              style="background-color: rgba(255, 255, 255, 0.75);"
              small
            >
              <v-icon v-if="fab">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
          </template>
          -->

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              fab
              small
              outlined
              color="#b31616"
              style="background-color: rgba(255,255,255,0.75);"
              class="mr-2 mb-2"
              @click="preview=true;"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>Open</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              fab
              small
              outlined
              color="#b31616"
              class="mr-2 mb-2"
              style="background-color: rgba(255,255,255,0.75);"
              @click="download()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download</span>
        </v-tooltip>
        <!--
        </v-speed-dial>
        -->
      </div>
    </div>
    <resource-dialog
      v-model="preview"
      v-if="preview"
      :name="name"
      :file="file"
    ></resource-dialog>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import ResourceDialog from "../components/resource_dialog.vue";
import { saveAs } from "file-saver";
export default {
  name: "ResourceCard",
  components: {
    CountryFlag,
    ResourceDialog,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: false,
      default: "de",
    },
    height: {
      type: Number,
      required: false,
      default: 190,
    },
  },
  data: () => ({
    fab: false,
    preview: false,
  }),
  methods: {
    getImgSrc: function () {
      return require(`../assets/marketing_material/${this.file}.png`);
    },
    getPdfSrc: function () {
      return require(`../assets/marketing_material/${this.file}.pdf`);
    },
    download: function () {
      const pdf = this.getPdfSrc();
      saveAs(pdf, this.name + ".pdf");
    },
  },
};
</script>

<style scoped>
.imageclass {
  transform: translate(-5px, -5px);
}
.container {
  border-style: solid;
  border-radius: 25px;
  border-width: thin;
  border-color: rgba(0, 0, 0, 0.35);
  padding: 0;
  overflow: hidden;
}
.show {
  border-style: dotted;
  border-width: thin;
  border-color: rgba(255, 0, 0, 0.35);
}
</style>
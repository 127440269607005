<template>
  <v-dialog
    v-model="dialog"
    max-width="90vw"
    height="90vh"
  >
    <v-card>
      <v-tooltip
        left
        v-if="!isLoading"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#b31616"
            fab
            large
            top
            right
            outlined
            fixed
            class="fab_button_class"
            v-on="on"
            v-bind="attrs"
            @click="dialog=false"
          >
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>

      <v-card-title>
        <menu-title :title="name">
          <span>{{ pageCount }} {{ pageCount > 1 ? "Pages" : "Page" }}</span>
        </menu-title>
      </v-card-title>
      <v-card-text>

        <v-overlay :value="isLoading">
          <v-progress-circular
            size="64"
            color="#b31616"
            indeterminate
          ></v-progress-circular>
        </v-overlay>

        <pdf
          v-for="i in pageCount"
          :key="i"
          :src="source"
          :page="i"
          class="mb-6"
          style="display: inline-block; width: 100%; outline: auto;"
        >
        </pdf>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
import menuTitle from "./helpers/menu_title.vue";
import axios from "axios";
export default {
  name: "ResourceDialog",
  components: {
    menuTitle,
    pdf,
  },
  props: {
    value: Boolean,
    file: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getFileSize: function (fileName) {
      const pdfFile = require(`../assets/marketing_material/${fileName}.pdf`);
      axios
        .get("http://localhost:8081" + pdfFile)
        .then((response) => {
          var length = Number(response.headers["content-length"]);
          this.fileSize = (length / 1000 / 1000).toFixed(2);
        })
        .catch((error) => {
          this.fileSize = 0.0;
        });
    },
  },
  data: () => ({
    isLoading: true,
    source: undefined, //placeholder
    pageCount: undefined,
    fileSize: 0,
  }),
  mounted() {
    this.source = pdf.createLoadingTask(
      require(`../assets/marketing_material/${this.file}.pdf`)
    );
    this.source.promise.then((pdf) => {
      this.pageCount = pdf.numPages;
      this.isLoading = false;
      //this.getFileSize();
    });
  },
};
</script>

<style>
.fab_button_class {
  top: 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
}
</style>